// App.js
import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import WeddingForm from './WeddingForm';
import ThankYou from './ThankYou';
import GuestList from './GuestList';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<WeddingForm />} />
        <Route path="/thank-you" element={<ThankYou />} />
        <Route path="/guests" element={<GuestList />} />
      </Routes>
    </Router>
  );
}

export default App;