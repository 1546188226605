import React, { useEffect, useState } from 'react';

function GuestList() {
    const [groupedGuests, setGroupedGuests] = useState([]);
    const [yesCount, setYesCount] = useState(0);

    const yesStyle = {
        color: 'green',
        fontWeight: 'bold'
    };

    const noStyle = {
        color: 'red',
        fontWeight: 'bold'
    };

    useEffect(() => {
        fetch('https://stankypoo.com:5000/all-guests')
            .then(res => res.json())
            .then(data => {
                const primaryGuests = data.filter(g => !g.primary_guest_id);
                const otherGuests = data.filter(g => g.primary_guest_id);

                let count = 0;
                const grouped = primaryGuests.map(primary => {
                    if (primary.rsvp === 1) count++;

                    const additional = otherGuests.filter(g => g.primary_guest_id === primary.id);
                    additional.forEach(g => {
                        if (g.rsvp === 1) count++;
                    });
                    return { primary, additional };
                });

                setGroupedGuests(grouped);
                setYesCount(count);
            });
    }, []);

    return (
        <div>
            <p class="big">Total YES responses: {yesCount}</p>
            <table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>RSVP</th>
                    </tr>
                </thead>
                <tbody>
                    {groupedGuests.map((group, idx) => (
                        <React.Fragment key={idx}>
                            <tr>
                                <td>{group.primary.name}</td>
                                <td>{group.primary.email}</td>
                                <td style={group.primary.rsvp === 1 ? yesStyle : noStyle}>
                                    {group.primary.rsvp === 1 ? 'YES' : 'NO'}
                                </td>
                            </tr>
                            {group.additional.map(guest => (
                                <tr key={guest.id}>
                                    <td style={{ paddingLeft: '20px' }}>
                                        ↖ {guest.name}
                                    </td>
                                    <td>{guest.email}</td>
                                    <td style={guest.rsvp === 1 ? yesStyle : noStyle}>
                                        {guest.rsvp === 1 ? 'YES' : 'NO'}
                                    </td>
                                </tr>
                            ))}
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default GuestList;
