import React, { useState, useEffect } from 'react';
import Switch from "react-switch";
import './App.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function WeddingForm() {
  const [primaryGuest, setPrimaryGuest] = useState({
    name: '',
    email: '',
    rsvp: true
  });

  const [guests, setGuests] = useState([]);
  const navigate = useNavigate();

  // Adjusting alignment based on container height
  useEffect(() => {
    const appContainer = document.querySelector('.app');
    function adjustAlignment() {
      if (appContainer.scrollHeight > window.innerHeight) {
        appContainer.style.justifyContent = 'flex-start';
      } else {
        appContainer.style.justifyContent = 'center';
      }
    }
    adjustAlignment();
    window.addEventListener('resize', adjustAlignment);
    return () => window.removeEventListener('resize', adjustAlignment); // Cleanup on unmount
  }, [guests]);

  const handlePrimaryChange = (e) => {
    setPrimaryGuest({
      ...primaryGuest,
      [e.target.name]: e.target.value
    });
  };

  const handleGuestChange = (index, e) => {
    const newGuests = [...guests];
    newGuests[index][e.target.name] = e.target.value;
    setGuests(newGuests);
  };

  const handleRSVPChange = (checked) => {
    setPrimaryGuest({
      ...primaryGuest,
      rsvp: checked
    });
  };

  const addGuest = () => {
    setGuests([
      ...guests,
      { name: '', email: '' }
    ]);
  };

  const removeGuest = (index) => {
    setGuests(guests.filter((guest, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post('https://stankypoo.com:5000/rsvp', { primaryGuest, guests });
      if (data.success) {
        navigate("/thank-you");
      } else {
        console.log('Failed to submit the form');
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div class="app">  
      <div className="form-container">
      <form onSubmit={handleSubmit}>
        <h1>Chris and Maggie are getting married!</h1>
        <p>Christopher Uehlein and Margaret Burke are getting married on September 23rd at the <a target="_blank" href="https://www.marylandclub1857.org/">Maryland Club</a> in Baltimore, Maryland. Please use the form below to RSVP.</p>
        <p>We are registered at the following outlets: </p> <p class="link"><a href="https://www.crateandbarrel.com/gift-registry/maggie-burke-and-chris-uehlein/r6776408" target="_blank">Crate and Barrel</a></p> <p class="link">  <a href="https://www.babylist.com/list/baby-margaret-burke" target="_blank">Baby List</a> </p> <p class="link"><a href="https://withjoy.com/maggie-and-chris-wedding/registry" target="_blank">With Joy</a> {'\n'} </p>
        <h1>Primary Guest</h1>
        <input name="name" value={primaryGuest.name} onChange={handlePrimaryChange} placeholder="Name" />
        <input name="email" value={primaryGuest.email} onChange={handlePrimaryChange} placeholder="Email" />
        <h2>R.S.V.P.</h2>
        <label className="switch-label">
          <span>No</span>
          <Switch 
            onChange={handleRSVPChange} 
            checked={primaryGuest.rsvp} 
            offColor="#800000"
            onColor="#008000"
            uncheckedIcon={false}
            checkedIcon={false}
          />
          <span>Yes</span>
        </label>
        {guests.map((guest, index) => (
          <div key={index} className="guest-input-container">
            <h2>Guest #{index + 1}</h2>
            <div className="guest-input-wrapper">
              <input name="name" value={guest.name} onChange={(e) => handleGuestChange(index, e)} placeholder="Name" />
              <input name="email" value={guest.email} onChange={(e) => handleGuestChange(index, e)} placeholder="Email" />
              <button className="button-remove" type="button" onClick={() => removeGuest(index)}>🗑</button>
            </div>
          </div>
        ))}
        <div className="button-container">
          <button className="button-add" type="button" onClick={addGuest}>Add a Guest</button>
          <button className="button-submit" type="submit">Submit</button>
        </div>
      </form>
    </div>
    </div>
  );
}

export default WeddingForm;
