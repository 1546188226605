// ThankYou.js
import React from 'react';

function ThankYou() {
  return (
    <div class="app">  
    <div className="form-container">
        <div class="thank-you">
      <h1>Thank You!</h1>
      <p>Your RSVP has been received successfully.</p>
      </div>
    </div>
    </div>
  );
}

export default ThankYou;
